import React from "react"
import { Link, graphql } from "gatsby"



export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`

interface BlogProps extends Partial<GatsbyProps> {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    },
    allMdx: {
      edges: Array<{
        node: {
          excerpt: any,
          fields: {
            slug: string
          }
        },
        frontmatter: {
          date: string,
          title: string,
          description: string
        }
      }>
    }
  }
}


class Blog extends React.Component<BlogProps, {}> {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    console.log(data);
    let v = data as any;
    console.log(v.location);
    return (
      <div>
        TheBlogPage
                <div>
          {posts.map(({ node }) => {
            return (<p>{node.excerpt}</p>)
          })}
        </div>
      </div>
    )
  }
}

export default Blog

